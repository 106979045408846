import ApiHelper from '../../helpers/ApiHelper'

export class ApiService {
    getBaseURL() {
        return ApiHelper.getBaseURL()
    }

    getModels() {
        return ApiHelper.get(`/v1/model/get`)
    }

    getModelById(id: string) {
        return ApiHelper.get(`/v1/model/get-by-id/${id}`)
    }

    getModelBySlug(slug: string) {
        return ApiHelper.get(`/v1/model/get-by-slug/${slug}`)
    }

    async reserveCall(data: {}) {
        return await ApiHelper.post(`/v1/call/reserve`, data)
    }
}

export default new ApiService()
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

const baseURL = process.env.NODE_ENV === 'production' ? 'https://api.call-ladies.com' : 'http://localhost'

export class ApiHelper {
    getBaseURL() {
        return baseURL
    }

    getAuthToken() {
        return sessionStorage.getItem('authToken') ? sessionStorage.getItem('authToken') : false
    }

    getOutgoingHeaders() {
        return {
            'authorization': this.getAuthToken(),
            'request-id': uuidv4(),
            'Request-Source': 'ladies_ui'
        }
    }

    async post(url: string, data: {}) {
        try {
            return await axios.post(url, data, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async postWithFiles(url: string, data: {}) {
        let headers = {
            'authorization': this.getAuthToken(),
            'request-id': uuidv4(),
            'Request-Source': 'ladies_ui',
            'Content-Type': 'multipart/form-data'
        }

        try {
            return await axios.post(url, data, {
                baseURL,
                headers
            })
        } catch (error) {
            throw error
        }
    }

    async put(url: string, data: {}) {
        try {
            return await axios.put(url, data, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async get(url: string) {
        try {
            return await axios.get(url, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async delete(url: string) {
        try {
            return await axios.delete(url, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }
}

export default new ApiHelper()